.staking-box {
    flex: 1;
    min-width: 450px;
    min-height: 350px;
    max-width: 650px;
    border-radius: 30px;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    padding: 24px;
    margin-bottom: 12px;
    margin-right: 12px;
    justify-content: center;
}

.staking-box-bordered {
}

.address-box {
}

.info-box {
    flex: 1;
    .staking-info-small-val {
        font-weight: bold;
        font-size: 12px;
    }
    .staking-info-val {
        font-weight: bold;
    }
}


.main-staking-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
}

.contract-top {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .contract-title-box {
        display: flex;
        flex-direction: column;
    }

    .contract-title {
        font-size: 28px;
        font-weight: 800;
    }

    .contract-logo {
        width: 50px;
        height: 50px;
        margin-right: 8px;
    }

    .contract-logo img {
        width: 50px;
        height: 50px;
        margin-right: 8px;
    }
    .contract-sub-title {
        font-size: 18px;
        font-weight: 500;
    }
}

.contract-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    .details{
        border-radius: 20px;
    }
}




.space{
    margin: 0% 20%;
}

.details{

    width: 100%;
    border-radius: 10px;
    background-color: #dcf5f2;

    .listItem{
        display: flex;
        /* display: block; */
        justify-content: space-around;
        padding: 15px 0px;
        font-size: 18px;
        text-transform: capitalize;  
    }
}

.main_address{
    width: 100%;
}

.details_header{
    width: 10%;
}

.address_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-top: 8%;
}

.details_card{

    .graph{
        position: relative;
        width: 60%;
        height: 60%;
        margin: 0px auto;
        margin-top: 5%;
    }

    .mini_header{
        padding: 0px 20px;
    }
    perspective: 150rem;
    -moz-perspective: 150rem;
    position: relative;
    height: 30rem;
    box-shadow: 0 1.5rem 4rem black .15;

    &:hover &__side__front {
        transform: rotateY(180deg);
    }

    &:hover &__side__back {
        transform: rotateY(0deg);
    }
}

.button_container{
    button{
        span{
            font-size: 25px;
            padding: 10px 55px;
        }
    }
}

.ms-TextField {
    width: 100%;
}

.staking-maturity-counter {
    font-size: 16px;
    font-weight: 800;
}

.error {
  color: rgb(202, 80, 16);
  font-weight: 700;
}