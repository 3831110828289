.admin-content{
    display: flex;
    height: 500px;
    margin: 0px auto;
    padding: 150px;
    width: 100%;
    justify-content: space-around;
}

.category-tab{
    background-color: #e2bf7c29;;
    padding: 20px;
    width: 40%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
    align-content: center;
    align-items: center;
    text-transform: capitalize;
    font-size: 2em;
    font-weight: 600;
    box-shadow: 4px 4px #e8e8e8;
}

.category-tab:hover {background-color: #f5b94929;}

.category-tab:active {
  background-color: #f5b94929;
  box-shadow: 0 5px rgb(238, 238, 238);
  transform: translateY(4px) translateX(4px);
}

.login-content{
  margin: 2em auto;
  width: 60%;
}

.bod{
  margin: 0px auto;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
}