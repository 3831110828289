.listItem{
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    padding-top: 8px;
}

.ListLabel{
    flex: 1;
}

.ListValue {
    min-width: 150px;
}

.staking{
    display: flex;
    margin-top: 5%;
    justify-content: space-around;
    padding-top: 20px;
    padding-bottom: 10%;

        .ui-list__item__contentWrapper{
            margin: 30px 0px 30px 0px;
        }

        .ui-list__item__content{
            font-size: 1rem;
        }

        .details{
            border-radius: 20px;
        background-color: #dcf5f2;
        }

        input{
            margin-bottom: 10px;
            padding: 10px;
            border-radius: 20;
            overflow: visible;
            border-color: #0f7cf126;
            border-radius: 10px;
            border-bottom-width: 4px;
            height: 60px;
            font-size: 30px;
        }

        button {
            box-shadow: 0 1.5rem 2rem #00000057;
        }




    .space{
        margin: 0% 20%;
    }

    .details{

        width: 40%;
        border-radius: 10px;


    }

    .main_address{
        width: 44%;
    }

    .details_header{
        width: 10%;
    }

    .address_container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-top: 8%;
    }

    .details_card{

        .graph{
            position: relative;
            width: 60%;
            height: 60%;
            margin: 0px auto;
            margin-top: 5%;
        }

        .mini_header{
            padding: 0px 20px;
        }
        perspective: 150rem;
        -moz-perspective: 150rem;
        position: relative;
        height: 30rem;
        box-shadow: 0 1.5rem 4rem black .15;

        &__cta{
            color: white;
            text-align: center;
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &_value{
            color: white;
            font-size: 3rem;
            width: 100%;
            font-weight: 100;
            display: block;
            padding-bottom: 0px;
            margin-bottom: 0px;
            line-height: 1.3;
            text-transform: uppercase;  
        }

        &_sub{
            color: white;
            font-size: 1.2rem;
            font-weight: 100;
            position: absolute;
            bottom: 5px;
            text-align: right;
            padding-left: 10px;
            right: 20px;
        }

        
        &__side{
            position: absolute;
            height: 45rem;
            top:0;
            left:0;
            transition: all .8s ease;
            width: 100%;
            backface-visibility: hidden;
            border-radius: 3px;
            box-shadow: 0 1.5rem 2rem #00000057;

            &__front{
                background-color:#e8f3ff;
            }

            &__back{
                background-color: green;
                transform: rotateY(180deg);
            }

            &__front_pre{
                background-color: green;

            }

            &__back_pre{
                background-color:#e8f3ff;
                transform: rotateY(180deg);
            }
        }



        &:hover &__side__front {
            transform: rotateY(180deg);
        }

        &:hover &__side__back {
            transform: rotateY(0deg);
        }
    
    }

}
