
.nav-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 80px;
}


.no-display{
    display: none;
}

.ms-Modal {
    .body-42{
        padding: 20px;
        margin: 20px 20px;
        padding-left: 10px;
       
        border-width: 3px;
        border-radius: 10px;
        border-style: solid;
        border-color: #69656524;
        justify-content: space-between;
        display: flex;

        p{
            font-size: 1.2rem;
            font-weight: 500;
            display: flex;
            align-items: center;
            padding-bottom: 0px;
            margin-bottom: 0px;
        }

        img{
            width: 30px;
        }
    }

    .body-42:hover {
        border-width: 1px;
        border-style: solid;
        border-color: white;
    }
}

.ms-Panel-navigation{
    margin-top: 20px;
}


.ms-Panel-contentInner{
    text-align: center;
    margin: 30px 20px;

    .ms-Panel-content{
        padding-left: 0px;
    }

    .ms-Label{
        font-size: 20px;
    }
}

.logo_img{
    image-Rendering: 'auto' as "auto";
    height: 40px;
}

.connectionLabel {
    text-align: center;
    width: 80%;
    margin: 0px auto;
    margin-top: 3%;
    color: black;

    .ui-alert__body{
        padding: 10px;
    }
}