@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

$font: 'Quicksand', sans-serif;

body {
  background-color: #000000;
  font-family: $font;
}

.ms-Fabric--isFocusHidden {
  font-family: $font;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.transaction_tile{
  cursor: pointer;
}
iframe {
  display: none !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.progress {
height: 1.6rem !important;
}

.progress-bar {
  height: 1.6rem;
  background-color: #150e0e66 !important;
}

.example {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.container{
  animation-name: example;
  animation-duration: 1s;
}

.accordion__item:hover{
  border-bottom-style: solid;
  border-bottom-width: thin;
  border-bottom-color: rgba(128, 128, 128, 0.281);
  padding-bottom: 10px;
}

.accordion__item{
  padding: 10px 3px;
  cursor: pointer;
}

.accordion__button{
  &:focus{
    outline: none;
  }
}

.buttonwithloader{
  background-color: #008000ab; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
  }

}

.collapsed {
  animation-name: collapse;
  animation-duration: 1s;
  min-height: 55px;
}

.container-text {
  opacity: 1;
  animation-name: opac;
  animation-duration: 4s;
}

.more {
  height: 0px;
}

.opacitBefore {
  display: 0;
}

.footer{
  h1{
    z-index: 50;
    position: absolute;
    display: block;
    width: 100%;
    text-align: center;
  }
  svg{
    position: absolute;
  }
  header{    
    width: 100%;
  }
}

@keyframes example {
  from {max-height: 80px;}
  to {max-height: 390px;}
}

@keyframes collapse {
  from {min-height: 190px;}
  to {min-height: 50px;}
}

@keyframes opac {
  from {opacity: 0;}
  to {opacity: 1;}
}

.nav{
  justify-content: center;
  display: block !important;

  span{
      &.ui-label{
          display: flex;
          padding: 25px;
          justify-content: center;
          align-items: center;
          color: white;
          margin-top: 50px;
          width: 60%;
      }
  }

  .nav_detail{
    margin: 0px auto;
    display: flex;
    justify-content: center;
  }

  .nav-container{
    margin: 0px auto;
    display: flex;
    justify-content: center;
  }

}

@media (max-width: 1100px) {

  //unifyre settings
  h1{
    font-size: 27.5 !important;
  }

  h2{
    font-size: 22.5 !important;
  }

  h3{
    font-size: 17.5 !important;
  }

  h4{
      font-size: 15 !important;
  }

  p{
    font-size: 20 !important;
  }

  .withdraw_Container {
    flex-direction: column;
  }

  .nav-bar-container{
    .nav-bar {
      padding: 5px 0px;
      // display: block;
      display: flex;
      flex-direction: row;

      a{
        margin: 20px;
        padding: 10px 0px;
      }
    }
  }

  .nav-children{
    margin: 0px auto;
    padding: 0px 10px;
    display: flex;
    justify-content: space-around;
  }

  .contract-container{
    .staking-box {
      margin: 10px 10px;
      min-width: auto;
    }

    .contract-top .contract-title {
      font-size: 20px;
      font-weight: 800;
    }
  }

  .ListValue {
    text-align: center;
  }

  .ListLabel {
    font-size: 12px;
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
 
  .details{
      margin-top: 30px;
  }

  .nav{
      img{
          width: 200px
      }
  }
}

