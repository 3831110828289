.web-categories {
    .btnContainer{
        display: flex;
        color: white;
        justify-content: space-between;
        width: 100%;
        border-radius: 3px;
        padding-bottom: 10px;
        height: 60px;
        cursor: pointer;
    }

    .tokenInfo{
        display: flex;
        flex: 1;
        color: white;
        justify-content: flex-start;
        align-items: center
    }

    .tokenSymbol {
        margin: 0px 5px;
        display: flex;
        align-items: center
    }

    .cat_text_container{
        line-height: 1.4;
        width: 50%;
        white-space: nowrap;
    }

    .cat_categoryText{
        letter-spacing: 1.5;
        font-weight: 600;
        font-size: 34px;
    }

    .percent{
        margin-right:0px;
        justify-content: flex-end;
        align-items: center;
        display: flex;
        min-width: 17%;

        .symb-mini {
            font-size: 18px;
            text-align: right;
            font-weight: bold;
            letter-spacing: 1
        }

        .symb{
            font-size: 30px;
            text-align: right;
            font-weight: bold;
            letter-spacing: 1
        }
        
        .symb-token{
            font-weight: lighter;
            margin-left: 4px;
        }

        .number-desc-mini {
            font-size: 8px;
            text-align: left;
            font-weight: bold;
            margin-left: 4px;
            margin-right: 4px;
        }

        .number-desc {
            font-size: 12px;
            text-align: left;
            font-weight: lighter;
            margin-left: 4px;
            margin-right: 4px;
        }
    }

    .stakingCapText{
        font-size: 50px;
        font-weight: 600;
        color: white;
        letter-spacing: 1.5;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0px
    }

    .progessBarContainer{
        padding-top: 20px
    }

    .miniText2{
        font-size: 11px;
        margin: 5px 0px;
        margin-top: 0px;
        margin-bottom: 5px
    }

    .rewards-btn {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        margin: 4px 0px;
        border-radius: 8px;
        padding: 12px 50px;
        justify-content: center;
        align-items: center;
        display: flex;
        height: 68px;
        min-width: 268px;
        cursor: pointer
    }
}

.percent-wrapper-vertical {
    display: flex;
    flex-direction: column;
}

@media (max-width: 1100px) {
    .web-categories {
        padding: 0px 25px;

        .stakingCapText {
            font-size: 35px;
        }

        .tokenInfo {
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .progessBarContainer {
            padding-top: 5px;
        }

        .rewards-btn{
            font-size: 20px;
            padding: 11px 13px;
            margin: 15px auto;
            margin-bottom: 0px;
            height: 51px;
            min-width: 50px;
            margin: 15px 58px;
            background-color: rgb(202, 165, 97);
            color: rgb(37, 40, 45);
        }

        .btnContainer{
            display: block;
            color: white;
            justify-content: space-between;
            width: 100%;
            border-radius: 3px;
            padding-bottom: 5px;
            height: auto;
            cursor: pointer;
        }

        .percent{
            padding: 2px 0px;
            justify-content: flex-start;
            text-overflow: ellipsis;
            overflow: hidden;
            .symb{
                font-size: 25px;
            }

            .number-desc {
                font-size: 9px;
            }
        }

        .cat_text_container{
            margin: 5px 0px;

            .cat_categoryText{
                font-size: 25px;
            }
        }

        .stakingCapText{
            display: block;
            margin: 5px 0px
        }

        .percent{
            justify-content: flex-start;
        }
    }
}
