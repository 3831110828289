
.search-fields{
    width: 50%;
    margin: 5em auto;

    
}

.info-card{
    background-color: #e3be7821;
    margin: 20px 1px;
    padding: 12px 25px;
    font-weight: bold;

    .info{
        margin: 10px auto;
        font-size: 1em;

       
    }

    &:hover {
        background-color: #f5b94929;
        box-shadow: 0 5px rgb(238, 238, 238);
        transform: translateY(2px);
    }
}

.form-header{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.ms-TextField-fieldGroup{
    height: 3em;
    border-radius: 10px;

    &::after{
        border-radius: 10px;
    }
}

.ui-dropdown{
    border-radius: 10px;
    width: 100% !important;

    &__container{
        height: 3em;
        border-radius: 10px;
        width: 100% !important;

    }

    &::after{
        border-radius: 10px;
    }
}

.field-container{

    .ui-textarea{
        min-height: 30em;
    }

}

#downshift-1-menu {
        width: 100%;
}